var site = site || {};

site.template = site.template || {};
(function ($) {
  Drupal.behaviors.customSort = {
    attach: function (context) {
      var $sortSelect = $('.js-mpp-sort-menu', context);

      $sortSelect.off('change').on('change', function (e) {
        $(document).trigger('custom_kits:sorting', this.value);
        e.preventDefault();
      });
    }
  };
})(jQuery);
